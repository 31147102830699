@font-face {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    src: url("./SpaceGrotesk-Light.ttf");
}

@font-face {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 400;
    src: url("./SpaceGrotesk-Regular.ttf");
}

@font-face {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 500;
    src: url("./SpaceGrotesk-Medium.ttf");
}

@font-face {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    src: url("./SpaceGrotesk-SemiBold.ttf");
}

@font-face {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    src: url("./SpaceGrotesk-Bold.ttf");
}